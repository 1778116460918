const burgerIcons = document.querySelectorAll('.checkbox'),
    leftNavBars = document.querySelectorAll('#left-nav');

const searchForms = document.querySelectorAll('#search-form'),
    searchIcons = document.querySelectorAll('#search-icon'),
    closeIcons = document.querySelectorAll('#close-search');

const toggleNavBar = (elements, action, className, parents) => {
    let toggleVal = false;
    elements.forEach(element => {
        element.addEventListener('click', () => {
            if (action === 'add') {
              if (toggleVal) {
                parents.forEach(parent => {
                  parent.classList.remove(className);
                })
                toggleVal = false;
              } else {
                parents.forEach(parent => {
                  parent.classList.add(className);
                })
                toggleVal = true;
              }
            }
            else {
              parents.forEach(parent => {
                parent.classList.remove(className);
              })
            }
        });
    })
}

toggleNavBar(burgerIcons, 'add', 'menu_burger--open', leftNavBars);
toggleNavBar(searchIcons, 'add', 'search--show', searchForms);
toggleNavBar(closeIcons, 'remove', 'search--show', searchForms);


const navbarRelative = document.querySelector(".relative_nav");
const navbarSticky = document.querySelector(".sticky_nav");

window.addEventListener("scroll", () => {
  let navPos = navbarRelative.getBoundingClientRect().bottom;
  let scrollPos = window.scrollY;
  const top_header_relative_note = document.querySelector('.internal_section_header.note.relative_nav');
  const top_header_relative = document.querySelector('.internal_section_header.relative_nav');
  const top_header_sticky = document.querySelector('.sticky_nav');
  const menu_burger_open = document.querySelector('.menu_burger--open');
  const check_sticky_burger = document.querySelectorAll('.hamburger input');
  const slideInUp = document.querySelectorAll('.slideInUp');
  const socialNetworks = document.querySelectorAll(".sticky-bar-mobile a");
  const shareIcon = document.getElementById("share-icon");

  if (menu_burger_open)
    check_sticky_burger.forEach((e,i) => {
      check_sticky_burger[i].checked = true;
    });
  else
    check_sticky_burger.forEach((e,i) => {
      check_sticky_burger[i].checked = false;
    });

  if (scrollPos > navPos) {
    navbarSticky.classList.add('sticky_nav--show');

    if (document.querySelector('.sticky_nav--show') && menu_burger_open) {
      navbarSticky.style.setProperty('transition', 'top 0s ease');
    }
    else {
      navbarSticky.style.setProperty('transition', 'top 0.6s ease')
    }

    if (top_header_sticky){
      const top_value = top_header_sticky.clientHeight;
      const string_value = `${top_value}px`;
      if (top_value <= 0) {
        leftNavBars.forEach((e, i) => {
          leftNavBars[i].classList.add('hidden');
          if (slideInUp) {
            slideInUp.forEach((e, i) => {
              slideInUp[i].classList.remove('slideInUp');
            });
          }
        });
      }
      else {
        leftNavBars.forEach((e, i) => {
          leftNavBars[i].classList.remove('hidden');
          leftNavBars[i].style.setProperty('--top_header', string_value);
        });
      }
    }
  } else {
    if(shareIcon) shareIcon.classList.remove("sticky-bar-open");
    if(socialNetworks) socialNetworks.forEach(socialNetwork => {
      socialNetwork.classList.remove("show-icons");
    });

    navbarSticky.classList.remove('sticky_nav--show');
    if (menu_burger_open)
      check_sticky_burger.checked = true;
    else
      check_sticky_burger.checked = false;

    if (top_header_relative) {
      const top_value = top_header_relative.clientHeight - scrollPos;
      const string_value = `${top_value}px`;
      if (top_value <= 0) {
        leftNavBars.forEach((e, i) => {
          leftNavBars[i].classList.add('hidden');
          if (slideInUp) {
            slideInUp.forEach((e, i) => {
              slideInUp[i].classList.remove('slideInUp');
            });
          }
        });
      }
      else {
        leftNavBars.forEach((e, i) => {
          leftNavBars[i].classList.remove('hidden');
          leftNavBars[i].style.setProperty('--top_header', string_value);
        });
      }
    }
    else if (top_header_relative_note) {
      const top_value = top_header_relative_note.clientHeight;
      const string_value = `${top_value}px`;
      if (top_value <= 0) {
        leftNavBars.forEach((e, i) => {
          leftNavBars[i].classList.add('hidden');
          if (slideInUp) {
            slideInUp.forEach((e, i) => {
              slideInUp[i].classList.remove('slideInUp');
            });
          }
        });
      }
      else {
        leftNavBars.forEach((e, i) => {
          leftNavBars[i].classList.remove('hidden');
          leftNavBars[i].style.setProperty('--top_header', string_value);
        });
      }
    }
  }
});

const checkedSlideUp = document.querySelectorAll('.hamburger input');
const menu_burger = document.querySelectorAll('.menu_burger');

checkedSlideUp.forEach((e, i) => {
  checkedSlideUp[i].addEventListener('change', () => {
    if (checkedSlideUp[i].checked) {
      menu_burger.forEach((e, i) => {
        menu_burger[i].classList.add('slideInUp');
      });
    }
  });
});

window.addEventListener('load', () => {
  const top_header_relative_note = document.querySelector('.internal_section_header.note.relative_nav');
  const top_header_relative = document.querySelector('.internal_section_header.relative_nav');
  const top_header_sticky = document.querySelector('.sticky_nav');
  const navPos = navbarRelative.getBoundingClientRect().bottom;
  let scrollPos = window.scrollY;

  if (scrollPos < navPos) {
    if (top_header_relative_note) {
      const top_value = top_header_relative_note.clientHeight;
      const string_value = `${top_value}px`;
      leftNavBars.forEach((e, i) => {
        leftNavBars[i].style.setProperty('--top_header', string_value);
      });
    }

    else if (top_header_relative) {
      const top_value = top_header_relative.clientHeight;
      const string_value = `${top_value}px`;
      leftNavBars.forEach((e, i) => {
        leftNavBars[i].style.setProperty('--top_header', string_value);
      });
    }

  }
  else {
    if (top_header_sticky){
      const top_value = top_header_sticky.clientHeight;
      const string_value = `${top_value}px`;
      navbarSticky.classList.add('sticky_nav--show');
      leftNavBars.forEach((e, i) => {
        leftNavBars[i].style.setProperty('--top_header', string_value);
      });
    }
  }
});

window.addEventListener('resize', () => {
  const top_header_relative_note = document.querySelector('.internal_section_header.note.relative_nav');
  const top_header_relative = document.querySelector('.internal_section_header.relative_nav');
  const top_header_sticky = document.querySelector('.sticky_nav');

  if (top_header_relative_note) {
    const top_value = top_header_relative_note.clientHeight;
    const string_value = `${top_value}px`;
    leftNavBars.forEach((e, i) => {
      leftNavBars[i].style.setProperty('--top_header', string_value);
    });
  }

  else if (top_header_sticky){
    const top_value = top_header_sticky.clientHeight;
    const string_value = `${top_value}px`;
    leftNavBars.forEach((e, i) => {
      leftNavBars[i].style.setProperty('--top_header', string_value);
    });
  }
  else if (top_header_relative) {
    const top_value = top_header_relative.clientHeight;
    const string_value = `${top_value}px`;
    leftNavBars.forEach((e, i) => {
      leftNavBars[i].style.setProperty('--top_header', string_value);
    });

  }
});
